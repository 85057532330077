.sider-container[data-v-bfdad8d2] {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 8px;
}
.sider-container .btn-container[data-v-bfdad8d2] {
  margin-bottom: 8px;
}
.sider-container .tree-container[data-v-bfdad8d2] {
  overflow-y: auto;
}
