.title[data-v-dcd6c6f9] {
  margin-bottom: 0;
}
.organization-tabs[data-v-dcd6c6f9] {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.tab-container[data-v-dcd6c6f9] {
  height: 100%;
}
