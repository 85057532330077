.member[data-v-3cc7a199] {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.member .btn-container[data-v-3cc7a199] {
  text-align: right;
  margin-bottom: 8px;
}
.member .role[data-v-3cc7a199] {
  cursor: pointer;
  margin-right: 4px;
}
.member .role[data-v-3cc7a199]:hover {
  text-decoration: underline;
}
.member[data-v-3cc7a199] .member-cell .cell {
  overflow: hidden;
  white-space: nowrap;
}
