.form-container[data-v-55949002] {
  border: 1px solid #eee;
  height: 100%;
  overflow: auto;
}
.form-container[data-v-55949002] .form-section .form-column:first-child {
  padding-left: 15px;
}
.form-container .placeholder[data-v-55949002] {
  height: 500px;
  line-height: 500px;
  text-align: center;
}
