[data-v-a3dd8891] .delete {
  color: #f00;
}
.create_type[data-v-a3dd8891] {
  height: 24px;
}
.create-menu[data-v-a3dd8891] {
  border-right: 0;
  padding: 0;
}
.custom-tree-node[data-v-a3dd8891] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding-right: 8px;
}
.custom-tree-node .el-dropdown-link[data-v-a3dd8891] {
  cursor: pointer;
  display: flex;
  align-items: center;
  visibility: hidden;
}
.custom-tree-node:hover .el-dropdown-link[data-v-a3dd8891] {
  visibility: visible;
}
