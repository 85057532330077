.content[data-v-9ee6bc7a] {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.content[data-v-9ee6bc7a] .page-body {
  overflow: auto;
}
.content[data-v-9ee6bc7a] .page-head {
  position: static;
}
