.toggle-btn[data-v-97be3208] {
  display: flex;
  margin-right: var(--margin-sm);
  cursor: pointer;
  --icon-stroke: var(--gray-500);
}
.toggle-btn .sidebar-toggle-placeholder[data-v-97be3208] {
  transition: all 0.5s ease-in-out;
}
.toggle-btn .sidebar-toggle-icon[data-v-97be3208] {
  transition: all 0.5s ease-in-out;
  display: none;
}
.toggle-btn:hover .sidebar-toggle-placeholder[data-v-97be3208] {
  display: none;
}
.toggle-btn:hover .sidebar-toggle-icon[data-v-97be3208] {
  display: block;
}
